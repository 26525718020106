
import { Options, Vue } from 'vue-class-component';
import { WorkShiftTypeClient } from '@/services/Services';
import { showNotification } from '@/utils';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class WorkShiftTypeConfiguration extends Vue {

    newName: string = "";
    scoreBool: boolean = false;
    isAbsence: boolean = false;

    allTypes: OM.WorkShiftType[] = [];

    created() {
        this.init();
    }

    init() {
        this.scoreBool = false;
        WorkShiftTypeClient.getAll()
        .then(x => {
            this.allTypes = x;
        })
    }

    get saveDisabled() {
        return !this.newName;
    }

    addProperty() {
        var newScore = 0;
        if(this.scoreBool)
            newScore = 1;

        WorkShiftTypeClient.create(this.newName, newScore, this.isAbsence)
        .then(x => {
            showNotification(this.$localizationService.getLocalizedValue("Successo", "Successo"), this.$localizationService.getLocalizedValue("Informazioni salvate con successo", "Informazioni salvate con successo"));

            this.init();
        })
    }

}
